// src/plugins/axios.js
import axios from 'axios';

const axiosInstance = axios.create({

  baseURL: 'https://stock.albayyinah.fr',
  headers: {
    'Content-Type': 'application/json'
  }
});

export default axiosInstance;
