<template>
  <v-app>
    <SearchPage />
  </v-app>
</template>

<script>
import SearchPage from './components/SearchPage';

export default {
  components: {
    SearchPage
  }
}
</script>
